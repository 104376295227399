import React from 'react';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ServicesComp from "./components/ServicesComp";

const Home = () => {
    return (<>

        <Navbar/>

         <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                         <img src="images/h1-sl1.jpg" class="d-block w-100 slideImg" alt="..."/>
                        <div class="carousel-caption d-md-block">
                            <div class="display-table text-center">
                                        <div class="slider-des">
                                            <div class="sl-sub-title">
                                                Welcome To
                                                </div>
                                                <h1 class="sl-title white-color"> GWK (GRANDWALK) Travels</h1>
                                                <div class="sl-desc">
                                                Our travel packages include Study Visas,<br/>  Tourism/Visiting Visas, Settlement Visas, Hotel Reservation, Flight Booking,<br/>  Airport Transfer (pickup and drop-off) and General Immigration and Travel consulting.
                                                </div>
                                        </div>
                                        <div class="slider-bottom">
                                                <a href="/Contact-Us" class="btn btn-sm btn-warn">Contact Us</a>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                       <img src="images/h1-sl1.jpg" class="d-block w-100 slideImg" alt="..."/>
                        <div class="carousel-caption d-md-block">
                            <div class="display-table text-center">
                                        <div class="slider-des">
                                            <div class="sl-sub-title">
                                                Welcome To
                                                </div>
                                                <h1 class="sl-title white-color"> GWK (GRANDWALK) Travels</h1>
                                                <div class="sl-desc">
                                                Our travel packages include Study Visas,<br/>  Tourism/Visiting Visas, Settlement Visas, Hotel Reservation, Flight Booking,<br/>  Airport Transfer (pickup and drop-off) and General Immigration and Travel consulting.
                                                </div>
                                        </div>
                                        <div class="slider-bottom">
                                                <a href="/Contact-Us" class="btn btn-sm btn-warn">Contact Us</a>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                       <img src="images/h1-sl1.jpg" class="d-block w-100 slideImg" alt="..."/>
                        <div class="carousel-caption d-md-block">
                            <div class="display-table text-center">
                                        <div class="slider-des">
                                            <div class="sl-sub-title">
                                                Welcome To
                                                </div>
                                                <h1 class="sl-title white-color"> GWK (GRANDWALK) Travels</h1>
                                                <div class="sl-desc">
                                                Our travel packages include Study Visas,<br/>  Tourism/Visiting Visas, Settlement Visas, Hotel Reservation, Flight Booking,<br/>  Airport Transfer (pickup and drop-off) and General Immigration and Travel consulting.
                                                </div>
                                        </div>
                                        <div class="slider-bottom">
                                                <a href="/Contact-Us" class="btn btn-sm btn-warn">Contact Us</a>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
             </div>


        <section className="container secTwo">
            <div className="row">
                <div className="col-lg-6 ps-45">
                         <img class="img-fluid d-block w-100" src="images/imgMain.jpg"/>
                    </div>
                <div className="col-lg-6 pl-45">
                     <div className="row justify-content-center">
                         <div className="col-lg-12">
                                <span className="infoTop">About Us</span>
                                <h4 className="">GWK (GRANDWALK) Travels</h4>
                                <p className="introText">We are a specialist Travel, Study and Visa consulting firm operating out of Port Harcourt, Nigeria. We provide our clients quality and straightforward Visas and other Immigration and Travel services to countries including Canada, USA, UAE, China, Schengen member countries, and other European countries.</p>
                           </div>
                         <div className="col-lg-12">
                                <a href="/about" class="btn btn-sm btn-warn">Learn more</a>

                             </div>

                         </div>
                        
                    </div>

            </div>
        </section>


        <section className="secThree">
            <div className="title-head text-center text-white">
                <div className="row">
                    <div className="col-lg-4"> </div>
                    <div className="col-lg-4"> 
                        <h5 className="tit-sub"><span className="line">GWK Travels</span></h5>
                       </div>
                    <div className="col-lg-4"> </div>
              </div>
                <h2 className="">Some of our Services</h2>
                {/* <p className="tit-mid">Our services include a wide-range of Immgration, Travel and Study Visa arrangements<br/> specific to the need of our clients, such as:</p> */}
            </div>
            <div className="container">
                  <ServicesComp/>
               </div>

         </section>




        <section className="secFour">
            <div className="container">
               <div className="row">
                   </div>
             </div>
        </section>


        <Footer/>

         
          
    </>);
};

export default Home;