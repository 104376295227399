import React from 'react';



const Navbar = () => {
    return (
        <>

<div class="top-nav">
            <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7 col-sm-12 hidden-md">
                        <div class="contactInfo">
                        <ul class="nav">
                            <li>
                            <i class="fa fa-envelope soIcon"></i>
                            <a href="mailto:info@gwktravels.com"> info@gwktravels.com</a>
                            </li>
                            <li>
                                <i class="fa fa-phone-alt soIcon"></i>
                                <a href="tel:+234 913 716 3139"> +234 913 716 3139</a>
                            </li>
                            <li>
                                <i class="fa fa-clock soIcon"></i> 09:00 AM - 06:00 PM
                                </li>
                        </ul>
                        </div>
                </div>
                <div class="col-lg-6 col-md-5 col-sm-12">
                  <div class="float-end">
                     <ul className="nav">
                        <li className="conInfo">
                            <a href="https://instagram.com/"><i class="fab fa-twitter soIcon"></i></a>
                         </li>
                        <li className="conInfo">
                            <a href="https://twitter.com/"><i class="fab fa-instagram soIcon"></i></a>
                         </li>
                        <li className="conInfo">
                            <a href="https://www.linkedin.com/company/"><i class="fab fa-linkedin soIcon"></i></a>
                         </li>
                        <li class="get-btn"><a href="/Contact-Us">Contact Us</a></li>
                     </ul>
                   </div>
                </div>
            </div>

            </div>
         </div>

         

        <nav class="navbar navbar-expand-md navbar-light" id="navbarNav1">
              <div class="container">
                  
                   <a class="navbar-brand" href="./" id="logo-m"> 
                       <img src="images/logo.png" width="120" class="d-inline-block align-top" alt=""/>     
                    </a>

                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">

                      <ul class="navbar-nav  ms-auto nm-r">
                          <li class="nav-item">
                            <a class="nav-link waves-effect waves-light active-nav" href="./">Home</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-effect waves-light" href="/About">About Us</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link waves-effect waves-light" href="/Our-Services">Our Services</a>
                          </li>

                        {/* <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Our Services
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                          </ul>
                        </li> */}
                          <li class="nav-item">
                            <a class="nav-link waves-effect waves-light" href="/Contact-Us">Contact Us</a>
                          </li>
                        </ul>
                  </div>
                </div>
            </nav>
        



            
        </>
    );
}

export default Navbar;