import React from 'react';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import SubServ from "./components/SubServ";

const MigrationVisa = () => {
    return (
        

        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner text-center">
                                <h1 class="breadcrumbs-title text-white">Migration Visa/Services</h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link"href="/Our-Services">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link btn-yellow" href="">
                                        <i class="fa fa-arrow-right"></i> Migration Visa/Services</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                 </section>


                <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                        <div className="col-lg-8 ps-15">
                                <div className="row">
                                    <div className="col-lg-12 mt-md-4">
                                     <h4 className="">Migration Visa Service</h4>
                                         <p className="introText">If you plan on moving to a new or specific country to work, study or live, we are also here to help you apply for the right visa. </p>

                                        <p className="introText">Countries like Canada have Express Entry program, which is designed to manage direct migration to Canada. Applicants from anywhere in the world who meet certain eligibility criteria based on a point-based system known as Comprehensive Ranking System (CRS) are then considered for an invitation to apply for permanent residency, which could then lead to the candidate's migration to Canada. </p>


                                        <p className="introText">If you think that the Canada Express Entry program provides a path for you to migrate to Canada then do not hesitate to contact us on 09137163139 or by email at  info@gwktravels.com. </p>

                                        
                                    </div>
                                 </div>
                                    
                                </div>
                          <div className="col-lg-4 mt-md-4">
                                <Sidebar/>
                           </div>



                           <div className="col-lg-9 mt-md-4 ps-15">
                                <SubServ/>
                           </div>
                           <div className="col-lg-3 mt-md-4"></div>



                      </div>
                  </div>
              </section>


                <Footer/>


        </>

    );
};

export default MigrationVisa;