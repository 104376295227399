import React from 'react';

import Navbar from './components/Navbar';
import Footer from "./components/Footer";

const Contact = () => {
    return (
        <>
        <Navbar/>

        <section className="abtHeaderImg">
            <div className="abt-back">
                <div class="container">
                    <div class="breadcrumbs-inner text-center">
                        <h1 class="breadcrumbs-title text-white">Contact Us</h1>
                        <ul class="breadcrumbs nav">
                            <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                            <li className="nav-item">
                                <a className="nav-link btn-yellow" href="">
                                    <i class="fa fa-arrow-right"></i> Contact Us
                                 </a>
                             </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>


              <div class="container cnt">
               
                <div class="row mt-2 mn-4">
                   {/* <div class="col-md-10">  */}
                       
                       <form action="/Contactus.php" method="post" enctype="multipart/form-data" class="row">
                          
                           
                       <div class="col-md-1"></div>
                          
                           
                           <div class="col-md-6"> 
                           
                               <h2 class="">Write Us</h2>
                                <div class="mb-4">
                                  <input type="text" id="form34" class="form-control validate" placeholder="Full Name" name="name" required=""/>
                                </div>

                                <div class="mb-4">
                                  <input type="email" id="form29" class="form-control validate" placeholder="Email Address" name="email" required=""/>
                                 </div>

                                <div class="mb-4">
                                  <input type="text" id="form32" class="form-control validate" placeholder="Subject" name="subject" required=""/>
                                </div>

                                <div class="mb-4">
                                  <textarea type="text" id="form8" class="md-textarea form-control" rows="10" name="msg" placeholder="Your message"></textarea>
                                </div>
                               
                                <button class="btn btn-warn btn-block btn-sm waves-effect waves-light float-right" name="submit">Submit</button>
                           
                           </div>

                           <div class="col-md-1"></div>


                           <div class="col-md-4">
                               <div class="row mt-5">
                                   <div  class="col-md-12">                                      

                                        <div class="md-form mb-5">

                                        <h5 class="contact-title"><i class="fa fa-home mr-4"></i> Address:</h5>
                                        <p class="mb-4">Racheal Hotel, 3-5 Harbour Road, Port Harcourt.</p>

                                        <h5 class="contact-title">
                                        <i class="fa fa-phone-alt mr-4"></i> Office Phone
                                        </h5>
                                        <p className="mb-4"> <a href="tel:+234 913 716 3139"  class="footer-address">+234 913 716 3139</a></p>
                                    
                                        <h5 class="contact-title">
                                        <i class="fa fa-envelope mr-4"></i> Email Us
                                        </h5>
                                         <p><a href="mailto:info@gwktravels.com" class="footer-address"> Email: info@gwktravels.com</a></p>
                                        </div>
                                       
                                   </div>
                               </div>
                            </div>
                       
                       </form>
                       
                    {/* </div> */}
                </div>
                
                
              </div>





        <Footer/>
            
        </>
    );
};

export default Contact;