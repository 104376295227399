import React from "react";

import Navbar from './components/Navbar';
import Footer from "./components/Footer";

const About = () =>{
    return (
        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner text-center">
                                <h1 class="breadcrumbs-title text-white">About Us </h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                                    <li className="nav-item">
                                        <a className="nav-link btn-yellow" href="">
                                            <i class="fa fa-arrow-right"></i> About Us
                                         </a>
                                     </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

              <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                          <div className="col-lg-6  ps-45">
                              <img class="img-fluid d-block w-100" src="images/imgMain.jpg"/>
                          </div>
                        <div className="col-lg-6 pl-45">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12">
                                            {/* <span className="infoTop"></span> */}
                                            <h4 className="">GRANDWALK (GWK) Travels</h4>
                                        <p className="introText">We are a specialist Travel, Study and Visa consulting firm operating out of Port Harcourt, Nigeria. We provide our clients quality and straightforward Visas and other Immigration and Travel services to countries including Canada, USA, UAE, China, Schengen member countries, and other European countries.</p>
                                        <p className="introText"> Our travel packages include Study Visas, Tourism/Visiting Visas, Settlement Visas, Hotel Reservation, Flight Booking, Airport Transfer (pickup and drop-off) and general Immigration and Travel consulting.</p>
                                    </div>

                                    </div>
                                    
                                </div>
                          {/* <div className="col-lg-6 abt-detail">
                          </div> */}
                      </div>
                  </div>
              </section>


                <Footer/>


        </>
    )
}

export default About;