import React, {useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import SubServ from "./components/SubServ";

const StudyAbroad = () =>{

       


    return (
        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner text-center">
                                <h1 class="breadcrumbs-title text-white">Study Abroad</h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a  className="nav-link"href="/Our-Services">Services</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link btn-yellow" href="">
                                        <i class="fa fa-arrow-right"></i> 
                                             Study Abroad</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                        <div className="col-lg-8 ps-15">
                                <div className="row">
                                    <div className="col-lg-12 mt-md-4">
                                            <h4 className="">Study Abroad Services</h4>
                                        <p className="introText"> Our Study Abroad services make it easy for our student clients to gain admission and study at the best universities and colleges around the world.</p>
                                        

                                        <p className="introText">You have a greater chance of being successful in your career and get to live your dreams when you make the effort to study abroad, especially in select countries in Europe, the US, Canada and the UK. In many of these countries, you would automatically qualify to live, work and build your career immediately after graduation, through a Post-Study Visa and Permanent Residency programs. </p>

                                        <p className="introText">Another good news is that, we at GWK Travels can help you gain admission and study abroad in many choice countries regardless of your current level of education or age.  We can also help you gain admission and scholarship in top colleges in countries such as Canada and the UK with just your O'level certificate and without the need for EILTS or TOEFL. </p>

                                        <p className="introText">Our existing relationship with some of the best universities and colleges around the world, including in the UK, Canada and the US, is there to make sure that your Study Abroad application stand the best chance of being successful. TRY US!</p>

                                        <p className="introText"><i class="fa fa-arrow-right"></i>  Gain admission and Study Permit within 2 months.</p>
                                        <p className="introText"><i class="fa fa-arrow-right"></i> Gain admission without EILTS or TOEFL.</p>
                                        <p className="introText"><i class="fa fa-arrow-right"></i> Gain scholarship as part of your admission package.</p>
                                        <p className="introText"><i class="fa fa-arrow-right"></i> Gain admission with just your O'level certificate (for college admission). Also, no EILTS or TOEFL necessary.</p>


                                    </div>

                                    <div className="col-lg-12 mt-md-4">

  
                           <OwlCarousel items={2} margin={8} autoplay={true}  loop={true} dots={true}>  
                                <div ><img  className="img" src= {'images/studySlide/img1.jpeg'}/></div>    
                                <div><img className="img" src= {'images/studySlide/img5.jpg'}/></div>  
                                <div><img className="img" src= {'images/studySlide/img6.jpg'}/></div> 
                                <div><img  className="img" src= {'images/studySlide/img7.jpeg'}/></div>   
                                <div><img  className="img" src= {'images/studySlide/img2.jpeg'}/></div>  
                                {/* <div><img  className="img" src= {'images/studySlide/img4.jpeg'}/></div>   */}
                                <div><img  className="img" src= {'images/studySlide/img3.jpeg'}/></div>  
                                <div><img className="img" src= {'images/studySlide/img5.jpg'}/></div>  
                                <div><img className="img" src= {'images/studySlide/img6.jpg'}/></div>  
                                </OwlCarousel> 

                                    </div>
                                 </div>          
                         </div>
                          <div className="col-lg-4 mt-md-4">
                                  <Sidebar/>
                           </div>


                           <div className="col-lg-9 mt-md-4 ps-15">
                                <SubServ/>
                           </div>
                           <div className="col-lg-3 mt-md-4"></div>
 
                      </div>
                  </div>
              </section>


                <Footer/>


        </>
    )
}

export default StudyAbroad;