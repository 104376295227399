import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import StudyAbroad from './pages/StudyAbroad';
import VisaApplicationServices from './pages/VisaApplicationServices';
import VisitingTourismVisa from './pages/VisitingTourismVisa';
import VisaAppealServices from './pages/VisaAppealServices';
import MigrationVisa from './pages/MigrationVisa';
import TravelItinerary from './pages/Travel-Itinerary-and-Advisory';
import Contact from './pages/Contact';


function App() {
  return (
    <BrowserRouter>
        <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/About" element={<About />} />
           <Route path="/Our-Services" element={<Services />} />
           <Route path="/Study-Abroad" element={<StudyAbroad />} />
           <Route path="/Visa-Application-Services" element={<VisaApplicationServices/>}/>
           <Route path="/Visiting-Tourism-Visa" element={<VisitingTourismVisa/>}/>
           <Route path="/Migration-Visa" element={<MigrationVisa/>}/>
           <Route path="/Visa-Appeal-Services" element={<VisaAppealServices/>}/>
           <Route path="/Travel-Itinerary-and-Advisory" element={<TravelItinerary/>}/>
           <Route path="/Contact-Us" element={<Contact/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
