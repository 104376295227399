import React from "react";

import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import ServicesComp from "./components/ServicesComp";
import SubServ from "./components/SubServ";

const Services = () =>{
    return (
        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner text-center">
                                <h1 class="breadcrumbs-title text-white">Our Services</h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                                    <li className="nav-item">
                                        <a className="nav-link btn-yellow" href="">
                                            <i class="fa fa-arrow-right"></i> Our Services
                                         </a>
                                     </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="servSec">
                    <div className="title-head">
                    </div>
                    <div className="container">
                        <ServicesComp/>
                    </div>

                </section>


                <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                           <div className="col-lg-9 mt-md-4 ps-15">
                                <SubServ/>
                           </div>
                           <div className="col-lg-3 mt-md-4"></div>


                      </div>
                  </div>
              </section>






                <Footer/>


        </>
    )
}

export default Services;