import React from 'react';

const ServicesComp = () => {
    return (
        <>


         <div className="row">
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/studyAbroad.jpeg" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="">Study Abroad Services</a></h4>
                                            <div class="desc">Our Study Abroad services make it easy for our student clients to gain admission and study at the best universities and colleges...<a href="/Study-Abroad" className="btn-yellow">Learn more</a></div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/visa-application-service.png" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="">Visa Application Services</a></h4>
                                            <div class="desc">At GWK TRAVELS, We pride ourselves in helping our clients maximise their chances of their Visa application success...<a href="/Visa-Application-Services" className="btn-yellow">Learn more</a></div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/tour.jpg" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="/Visiting-Tourism-Visa">Visiting or Tourism Visas/Services</a></h4>
                                            <div class="desc">If you plan to visit or tour any country of your choice, then you can be rest assured that GWK TRAVELS is...<a href="/Visiting-Tourism-Visa" className="btn-yellow">Learn more</a></div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/passport.jpg" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="">Migration Visas/Services</a></h4>
                                            <div class="desc">If you plan on moving to a new or specific country to work, study or live, we are also here to help you apply for the right visa...<a href="/Migration-Visa" className="btn-yellow">Learn more</a></div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/VisaAppeal2.jpg" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="" >Visa Appeal Services</a></h4>
                                            <div class="desc">With GWK TRAVELS, our clients have a great chance of successfully appealing your denied visa application...<a href="/Visa-Appeal-Services" className="btn-yellow">Learn more</a> </div>
                                        </div>
                                    </div>
                                </div>
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div class="cad">
                                        <div class="icon-part">
                                            <img src="images/TravelItinerary&Advisory.jpg" className="img-fluid imgThumbnail" alt=""/>
                                        </div>
                                        <div class="content-part">
                                            <h4 class="title"><a href="" >Travel Itinerary &amp; Advisory</a></h4>
                                            <div class="desc">We desire to see our clients get the best travel experiences possible. So, we offer flight booking, hotel/accommodation reservations...<a href="/Travel-Itinerary-and-Advisory" className="btn-yellow">Learn more</a> </div>
                                        </div>
                                    </div>
                                </div>
                        </div>

            
        </>
    );
};

export default ServicesComp;