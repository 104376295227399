import React from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";

import SubServ from "./components/SubServ";
const VisitingTourismVisa = () => {
    return (
            

        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner">
                                <h1 class="breadcrumbs-title  text-center text-white">Visiting or Tourism Visas/Services</h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                                    <li className="nav-item"><a  className="nav-link"href="/Our-Services">Services</a></li>
                                    <li className="nav-item"><a className="nav-link btn-yellow" href="">
                                        <i class="fa fa-arrow-right"></i> Visiting or Tourism Visas/Services</a>
                                      </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                 </section>


                <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                        <div className="col-lg-8 ps-15">
                                <div className="row">
                                    <div className="col-lg-12 mt-md-4">
                                     <h4 className="">Visiting or Tourism Visas/Services</h4>
                                         <p className="introText">If you plan to visit or tour any country of your choice, then you can be rest assured that GWK TRAVELS is prepared to make your travel arrangements and visa application a success. </p>


                                        <p className="introText"> As part of your Visiting/Tourism visa application or travel plan, we can provide you with straightforward services regarding Visitor's or Tourists visa application, hotel reservation, airport transfer (airport pick-up and drop-off), and tour sites recommendation is specific to your intended destination. We also provide vacation/tourism deals to select destinations.</p>

                                      
                                        
                                    </div>

                                    <div className="col-lg-12 mt-md-4">

  
<OwlCarousel items={2} margin={8} autoplay={true} loop={true} >  
     <div ><img  className="img" src= {'images/tour/img1.jpeg'}/></div>    
     {/* <div><img className="img" src= {'images/tour/img5.jpeg'}/></div>   */}
     <div><img className="img" src= {'images/tour/img6.jpeg'}/></div> 
     <div><img  className="img" src= {'images/tour/img7.jpeg'}/></div>   
     <div><img  className="img" src= {'images/tour/img2.jpeg'}/></div>  
     <div><img  className="img" src= {'images/tour/img4.jpeg'}/></div>  
     <div><img  className="img" src= {'images/tour/img3.jpeg'}/></div>   
     </OwlCarousel> 

         </div>
                                 </div>
                                    
                                </div>
                          <div className="col-lg-4 mt-md-4">
                                <Sidebar/>
                           </div>



                           <div className="col-lg-9 mt-md-4 ps-15">
                                <SubServ/>
                           </div>
                           <div className="col-lg-3 mt-md-4"></div>



                      </div>
                  </div>
              </section>


                <Footer/>


        </>

    );
};

export default VisitingTourismVisa;