import React from 'react';

const SubServ = () => {
    return (
        <>


<div className="specialTab">
                  <div className="row">

                      <div className="col-md-12">
                          <hr className="mt-5"/>
                      <h4 className="mt-5">Some destinations we provide travel and visa services to: </h4>

                       <p className="introText"> We provide travel and visa services to any country/destination of your choice. Our dedicated and specialist visa and travel consultants are readily available to handle your visa application and help in making sure that your specific travel arrangement is successful. Our visa and travel packages are available to countries including:</p>
                      </div>

                      <div className="col-lg-6">
                        <div class="accordion" id="myAccordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">UK Visas and Travel Packages</button>									
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                            <p></p>
                                            <p className="introText"> Student Visa </p>
                                            <p className="introText"> Visitor Visa Application</p>
                                            <p className="introText"> Business Visa  </p>
                                            <p className="introText"> Tourist Visa </p>
                                            <p className="introText"> Dependent  Visa UK Ancestry Visa</p>
                                            <p className="introText"> Post-Study Work Visas</p>
                                            <p className="introText"> Work Visa</p>
                                            <p className="introText"> Spouse Visa Application</p>
                                            <p className="introText"> Fiance Visa</p>
                                            <p className="introText"> Skilled  Worker</p>
                                            <p className="introText"> Temporary Worker Visa</p>
                                            <p className="introText"> Sportsperson Visa</p>
                                            <p className="introText"> Medical Visa</p>

                                        <p className="introText mt-4 mb-4">  ...........................................</p>

                                            <p className="introText"> Flight booking </p>
                                            <p className="introText"> Airport pick-up and drop-off </p>
                                            <p className="introText"> Hotel and accommodation reservation </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo">Dubai Visa and Travel  Services</button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                        <p className="introText"> Business Visa</p>
                                        <p className="introText"> Tourist Visa</p>
                                        <p className="introText"> Medical Visa </p>
                                        <p className="introText mt-4 mb-4">  ...........................................</p>
                                        <p className="introText"> Flight booking </p>
                                        <p className="introText"> Airport pick-up and drop-off</p> 
                                        <p className="introText"> Hotel and accommodation reservation </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">Canada Visa and Travel Services</button>                     
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                            
                                            <p className="introText">Student Visa </p>
                                            <p className="introText">Medical Visa </p>
                                            <p className="introText">Visitor Visa </p>
                                            <p className="introText">Tourist Visa </p>
                                            <p className="introText">Work Visa </p>
                                            <p className="introText">Family Sponsorship Visa </p>
                                            <p className="introText">Permanent Residence Visa</p>
                                            <p className="introText">Business Visa</p>
                                            <p className="introText">Provincial Nominee Program</p>
                                            <p className="introText">Express Entry Program Visa</p>
                                            <p className="introText">Canada Self-Employed Visa </p>
                                            <p className="introText mt-4 mb-4">...........................................</p>
                                            <p className="introText">Flight booking </p>
                                            <p className="introText">Airport pick-up and drop-off </p>
                                            <p className="introText">Hotel and accommodation reservation </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSeven">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven">China Visa and Travel Services</button>                     
                                    </h2>
                                    <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                                                                
                                   <p className="introText">Business Visa </p>
                                   <p className="introText"> Study Visa</p>
                                   <p className="introText"> Medical Visa</p>
                                   <p className="introText"> Tourist Visa</p>
                                   <p className="introText"> --------------------------------------- </p>
                                   <p className="introText"> Flight booking </p>
                                   <p className="introText"> Airport pick-up and drop-off </p>
                                   <p className="introText"> Hotel and accommodation reservation</p> 

                                        </div>
                                    </div>
                                </div>
                         </div>
                        </div>


                      <div className="col-lg-6">
                        <div class="accordion" id="myAccordion">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">US Visa and Travel Services</button>									
                                    </h2>
                                    <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                        <p className="introText">Students Visa  (F-1)</p>
                                        <p className="introText">Student Dependents Visa (F-2)</p>
                                        <p className="introText">Students – Vocational Visa (M-1)</p>
                                        <p className="introText">Student Vocational Dependents Visa (M-2) </p>

                                        <p className="introText">Tourism/Vacation (B-2)</p>
                                        <p className="introText">Business visitors visa (B-1)</p>
                                        <p className="introText">Domestic employees or nannies (B-1)</p>
                                        <p className="introText">Medical Treatment, Visitors Visas  (B-2) </p>

                                        <p className="introText">Fiancé(e) Visa (K-1)</p>
                                        <p className="introText">Spouse Visa (CR1) </p>

                                        <p className="introText">Athletes Visa (B-1)</p>
                                        <p className="introText">Entertainers Visa (P)</p>
                                        <p className="introText">Media Personnel Visa (I)</p>
                                        <p className="introText">Intra-company Transferees Visa (L)</p>
                                        <p className="introText">Nurses (H-1C)</p>
                                        <p className="introText">Physicians/Medical Professionals Visa (J-1, H-1B)</p>
                                        <p className="introText">Highly Qualified Specialist Visa (H-1B) </p>
                                        <p className="introText mt-4 mb-4">-----------------------------------</p>
                                        <p className="introText">Flight booking </p>
                                        <p className="introText">Airport pick-up and drop-off </p>
                                        <p className="introText">Hotel and accommodation reservation </p>


                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">Australia Visa and Travel Services</button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                        <p className="introText"> Study or Training Visas</p>
                                        <p className="introText"> Visitor Visas</p>
                                        <p className="introText"> Work Visas</p>
                                        <p className="introText"> Permanent Residence Visas</p>
                                        <p className="introText"> Spouse and Family Visas </p>
                                        <p className="introText"> ---‐--------------------------------------- </p>
                                        <p className="introText"> Flight booking </p>
                                        <p className="introText"> Airport pick-up and drop-off </p>
                                        <p className="introText"> Hotel and accommodation reservation </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingSix">
                                        <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">Europe/Schengen Area Visa and Travel Services</button>                     
                                    </h2>
                                    <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                        <div class="card-body">
                                                                                
                                   <p className="introText"> Medical Visa</p>
                                   <p className="introText"> Student Visa</p>
                                   <p className="introText"> Visitor Visa</p>
                                   <p className="introText"> Tourist Visa</p>
                                   <p className="introText"> Family/Spouse Visa</p>
                                   <p className="introText"> Asylum Application </p>
                                   <p className="introText"> Work Visa</p>
                                   <p className="introText"> --------------------------------------- </p>
                                   <p className="introText"> Flight booking </p>
                                   <p className="introText"> Airport pick-up and drop-off </p>
                                   <p className="introText"> Hotel and accommodation reservation</p> 

                                        </div>
                                    </div>
                                </div>
                         </div>
                        </div>




                  </div>
               </div>
 
            
        </>
    );
};

export default SubServ;