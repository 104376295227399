import React from 'react';

const Footer = () =>{
    return (
        <>



<footer class="page-footer font-small unique-color-dark mt-4">

<div class="py-4" id="consult">
    {/* <div class="container text-center">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-5">
                    <h3>Download Our Profile</h3>                
                </div>
                <div class="col-md-5"> 
                <a href="img/Substrata_Corporate_Profile.pdf" download="">
                    <button href="" type="button" class="btn btn-warn btn-sm waves-effect waves-light">Download<i class="fa fa-paper-plane-o ml-1"></i></button>
                    </a>                
                </div>
                <div class="col-md-1"></div>
            </div>
    </div> */}
</div>


<div class="container text-md-left mt-5">


    <div class="row mt-3">


    <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
    <div class="">
            {/* <img src="images/logo.png" width="150" style={{float:'left'}} class="img-fluid d-block w-100" alt=""/>  */}
        </div>
    <div class="footer-info pb-5">
                    <p class="footer-desc">We are a specialist Travel, Study and Visa consulting firm operating out of Nigeria. Our travel packages include Study Visas, Tourism/Visiting Visas, Settlement Visas, Hotel Booking, Flight Booking, Airport Transfer (pickup and drop-off) and general Immigration and Travel consulting. </p>
                </div>

    </div>
    
    <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">


        <h6 class="text-uppercase font-weight-bold">Our Services</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px', color: '#f7c301', border: '1px solid'}}/>
        <p>
        <a href="" class="footer-address">Study Abroad</a>
        </p>
        <p>
        <a href="" class="footer-address">Visa Application Services</a>
        </p>
        <p>
        <a href="" class="footer-address">Visiting/Tourism Visa</a>
        </p>
        <p>
        <a href="" class="footer-address">Visa Appeal Services</a>
        </p>

    </div>

                 <span class="socialMedia">
								<a href="https://wa.me/+2349137163139" class="btn btn-icon-2 btn-circle" role="button">
									<i class="fab fa-whatsapp"></i> <span>WhatsApp Chat</span>
                                </a>
							
						</span>

      

    <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">


        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: '60px', color: '#f7c301', border: '1px solid'}}/>
        <p>
        <i class="fa fa-home mr-4 soIcon"></i> Racheal Hotel, 3-5 Harbour Road, Port Harcourt.</p>
        <p>
        <i class="fa fa-envelope mr-4 soIcon"></i> <a href="mailto:info@gwktravels.com" class="footer-address"> info@gwktravels.com</a></p>
        <p>
        <i class="fa fa-phone-alt mr-4 soIcon"></i> <a href="tel:+234 913 716 3139"  class="footer-address"> +234 913 716 3139</a></p>

    </div>
    
    <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">



    </div>
    
    

    </div>
    

</div>

<div class="footer-copyright text-center py-3">© 2021 Copyright:
    <a href=""> GRANDWALK TRAVELS</a>
</div>

</footer>




            
        </>
    );
}

export default Footer;