import React from 'react';

const Sidebar = () => {
    return (
        <>


            <div class="sidebar">
                    <div class="sidebar-common mb-35">
                        <h4 class="sidebar-title mb-34">Our Services</h4>
                        <ul>
                            <li class="current-x">
                                <a href="/Study-Abroad">
                                    <i class="fa fa-chevron-right"></i>Study Abroad</a>
                                </li>
                            <li>
                                <a href="/Visa-Application-Services">
                                    <i class="fa fa-chevron-right"></i>Visa Application Services</a> </li>
                            <li>
                                <i class="fa fa-chevron-right"></i> 
                                <a href="/Visiting-Tourism-Visa"> 
                                Visiting or Tourism Visas/Services</a> </li>
                            <li>
                                <i class="fa fa-chevron-right"></i>
                                <a href="/Migration-Visa"> 
                                     Migration Visa/Services</a> </li>
                            <li>
                                <i class="fa fa-chevron-right"></i> 
                                <a href="/Visa-Appeal-Services"> 
                                     Visa Appeal Services</a> </li>
                            <li>
                                <i class="fa fa-chevron-right"></i>
                                <a href="/Travel-Itinerary-and-Advisory">
                                     Travel Itinerary  &amp; Advisory</a> </li>
                        </ul>
                    </div>
                </div>
            
        </>
    );
};

export default Sidebar;