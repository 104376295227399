import React from 'react';
import Navbar from './components/Navbar';
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import SubServ from "./components/SubServ";

const TravelItinerary = () => {
    return (
        

        <>

                <Navbar/>

                <section className="abtHeaderImg">
                    <div className="abt-back">
                        <div class="container">
                            <div class="breadcrumbs-inner text-center">
                                <h1 class="breadcrumbs-title text-white">Travel Itinerary  &amp; Advisory </h1>
                                <ul class="breadcrumbs nav">
                                    <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                                    <li className="nav-item"><a  className="nav-link"href="/Our-Services">Services</a></li>
                                    <li className="nav-item">
                                        <a className="nav-link btn-yellow" href="">
                                            <i class="fa fa-arrow-right"></i> Travel Itinerary  &amp; Advisory
                                         </a>
                                     </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                 </section>


                <section className="abt">
                  <div className="container">
                      <div className="row mt-5">
                        <div className="col-lg-8 ps-15">
                                <div className="row">
                                    <div className="col-lg-12 mt-md-4">
                                     <h4 className="">Travel Itinerary &amp; Advisory</h4>
                                         <p className="introText">We desire to see our clients get the best travel experiences possible. So, we offer flight booking, hotel/accommodation reservations, airport transfer and travel advisory services.</p>

                                        <p className="introText">We pride ourselves in our commitment in making sure our clients' travel experiences are as seamless as possible. So, we are more than happy to help you arrange all your travel Itineraries. For example, we can assist you alleviate your accommodation stress, which is common when travelling to a new destination, by helping you reserve an accommodation within your budget. We can also help you with flight bookings, hotel bookings, airport transfers (pick-up and drop-off). 
</p>


                                        <p className="introText">We also provide professional support and advice regarding expectations and latest events specific to your travel destination, including tour sites recommendation, immigration and visa concerns, government and travel regulations, culture and social expectations and advice, and all other concerns and questions you might have.</p>

                                        
                                    </div>
                                 </div>
                                    
                                </div>
                          <div className="col-lg-4 mt-md-4">
                                <Sidebar/>
                           </div>

                           <div className="col-lg-9 mt-md-4 ps-15">
                                <SubServ/>
                           </div>
                           <div className="col-lg-3 mt-md-4"></div>

                      </div>
                  </div>
              </section>


                <Footer/>


        </>

    );
};

export default TravelItinerary;